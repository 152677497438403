<template>
  <div>
    <base-card :loading="loading" class="rounded-lg pb-2">
      <div class="d-flex justify-center text--disabled align-center gap-2 pt-1 unselectable">
        <small>
          {{ party?.name || "Carregando..." }}
        </small>
        <small> • </small>
        <small> Operacional </small>
      </div>
      <v-tabs v-model="tab" show-arrows class="rounded-lg" height="36px">
        <v-tab
          v-for="tab in loading ? [] : tabsFiltered"
          class="rounded-lg"
          :key="tab.component"
        >
          <v-icon left small>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in loading ? [] : tabsFiltered"
          :key="item.name"
        >
          <component
            :is="item.component"
            :party="party"
            @refresh="getParty"
            class="px-4 pb-4"
          />
        </v-tab-item>
      </v-tabs-items>

      <refund-ticket :party="party" />
    </base-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PartyTicketsManagement from "@/components/admin/party/PartyTicketsManagement";
import PartyTickets from "@/components/admin/party/PartyTicketsSold.vue";
import PartyReports from "@/components/admin/party/PartyReports.vue";
import PartyLiveAnalytics from "@/components/admin/party/PartyLiveAnalytics.vue";
import OfflinePaymentsManagement from "@/components/admin/party/OfflinePaymentsManagement.vue";
import SaleLinkManagement from "@/components/admin/party/SaleLinkManagement.vue";
import TicketRefunds from "@/components/admin/party/TicketRefunds.vue";
import CourtesyEmitter from "@/components/admin/party/CourtesyEmitter.vue";
import RefundTicket from "@/components/admin/modals/RefundTicket.vue";
import PartySeller from "@/components/admin/party/PartySeller";
import PartyTicketOffice from "@/components/admin/party/PartyTicketOffice";

export default {
  metaInfo() {
    return { title: `⚙️ ${this.party?.name || "Carregando..."}` };
  },
  data: () => ({
    loading: true,
    party: null,
    tab: null,
    tabs: [
      {
        id: "tickets",
        name: "Ingressos",
        component: "PartyTicketsManagement",
        permission: 16,
        icon: "mdi-ticket",
      },
      {
        id: "guests",
        name: "Convidados",
        component: "PartyTickets",
        permission: 64,
        icon: "mdi-account-multiple",
      },
      {
        id: "reports",
        name: "Relatórios",
        component: "PartyReports",
        permission: 512,
        icon: "mdi-chart-bar",
      },
      {
        id: "analytics",
        name: "Analytics",
        component: "PartyLiveAnalytics",
        permission: 512,
        icon: "mdi-chart-line",
      },
      {
        id: "offlinePayments",
        name: "Recebimentos Externos",
        component: "OfflinePaymentsManagement",
        permission: 8192,
        icon: "mdi-tray-arrow-down",
      },
      {
        id: "sellers",
        name: "PDV & Vendedores",
        component: "PartySeller",
        permission: [2, 16],
        icon: "mdi-account-group",
      },
      {
        id: "ticketOffice",
        name: "Bilheteria",
        component: "PartyTicketOffice",
        permission: [2, 16],
        icon: "mdi-ticket-account",
      },
      {
        id: "refound",
        name: "Reembolsos",
        component: "TicketRefunds",
        permission: 128,
        icon: "mdi-cash-refund",
      },
      // {
      //   id: "saleLinks",
      //   name: "Links de Venda",
      //   component: "SaleLinkManagement",
      //   permission: 16384,
      //   icon: "mdi-qrcode",
      // },
      {
        id: "courtesy",
        name: "Cortesias",
        component: "CourtesyEmitter",
        permission: 524288,
        icon: "mdi-account-star",
      },
    ],
  }),
  methods: {
    ...mapActions("organization", ["partyById"]),
    async getParty() {
      try {
        this.loading = true;
        var response = await this.partyById(this.$route.params.partyId);
        this.party = response;
        this.loading = false;
        this.tab = 0;
        this.selectTab();
        this.$root.$emit("setImgBg", this.party?.cover);
      } catch (error) {
        this.$router.push({ name: "admin.parties" });
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
    tabsFiltered() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.hasPermission(tab.permission)
      );
    },
  },
  watch: {
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
    "$route.hash"() {
      this.selectTab();
    },
    "$route.params.partyId"() {
      this.getParty();
    },
    "selectedOrganization.id"() {
      this.$router.push({
        name: "admin.parties",
      });
    },
  },
  components: {
    PartyTicketsManagement,
    PartyReports,
    PartyLiveAnalytics,
    PartyTickets,
    OfflinePaymentsManagement,
    SaleLinkManagement,
    CourtesyEmitter,
    TicketRefunds,
    RefundTicket,
    PartySeller,
    PartyTicketOffice,
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
