<template>
  <v-card outlined class="pa-2">
    <h6 class="mb-0">Cortesias emitidas</h6>

    <v-alert v-if="error" type="error" class="mb-0">{{ error }}</v-alert>
    <v-data-table
      :headers="headers"
      :items="tickets"
      :loading="loading"
      item-key="id"
      sort-by="Payment.createdAt"
      sort-desc
    >
      <template v-slot:item.Owner.name="{ item }">
        <div class="d-flex align-center gap-2">
          <base-avatar :src="item.Owner.photo" :seed="item.Owner.id" />
          <div>
            <b class="d-block lh-1">{{ item.Owner.name }}</b>
            <small class="lh-1">{{ item.Owner.phone }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.Seller.name="{ item }">
        <div v-if="item.Seller" class="d-flex align-center gap-2">
          <base-avatar :src="item.Seller?.photo" :seed="item.Seller?.id" />
          <div>
            <b class="d-block lh-1">{{ item.Seller?.name || "Conta não criada" }}</b>
            <small class="lh-1">{{ item.Seller?.phone }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.Payment.createdAt="{ item }">
        {{ item.Payment.createdAt | date("DD/MM/YYYY HH:mm") }}
      </template>
      <template v-slot:item.TicketBlock.name="{ item }">
        <div class="d-flex flex-column">
          <b>{{ item.TicketBlock.TicketGroup.name }}</b>
          <span class="lh-1">{{ item.TicketBlock.name }}</span>
        </div>
      </template>
      <template v-slot:item.Payment.status="{ item }">
        <v-chip
          :color="PAYMENT.ticketStatus[item.Payment.status].color"
          small
          label
        >
          {{ PAYMENT.ticketStatus[item.Payment.status].text }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PARTY from "@/services/admin/party";
import PAYMENT from "@/utils/payment";
export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAYMENT,
      tickets: [],
      loading: false,
      error: null,
      headers: [
        { text: "Contemplado", value: "Owner.name" },
        { text: "Emitida por", value: "Seller.name" },
        { text: "Data", value: "Payment.createdAt" },
        { text: "Lote", value: "TicketBlock.name" },
        { text: "status", value: "Payment.status" },
      ],
    };
  },
  methods: {
    async getTickets() {
      this.loading = true;
      try {
        const { tickets } = await PARTY.reports.courtesy(
          this.party.organizationId,
          this.party.id
        );
        this.tickets = tickets;
      } catch (error) {
        this.error = error.message || "Erro ao buscar cortesias.";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getTickets();
    this.$root.$on("refresh-courtesy", this.getTickets);
  },
};
</script>

<style>
</style>