<template>
  <v-card outlined :loading="loading">
    <v-card-title class="d-flex justify-space-between pb-0">
      <h6>Pagamentos por canal</h6>
      <v-btn-toggle mandatory v-model="display" class="ml-2">
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <v-alert
        type="warning"
        dense
        class="mb-0"
        v-if="!chartSeries.length"
        outlined
      >
        Nenhum pagamento válido.
      </v-alert>

      <apexCharts
        v-else
        type="donut"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,

    channelTranslation: {
      pos: "PDV",
      ticketOffice: "Bilheteria",
      shop: "Site",
      seller: "Promoter",
    },
  }),
  computed: {
    paymentTypes() {
      return this.data.payments
        .filter((payment) => payment.status == "succeeded")
        .reduce((acc, p) => {
          var channel = "seller";

          if (p.PosSession) {
            channel = "pos";
            if (p.PosSession.partyId) channel = "ticketOffice";
          } else if (p.fromShop) channel = "shop";

          if (!acc[channel]) {
            acc[channel] = 0;
          }
          acc[channel] += this.display ? 1 : p.amount - p.platformFee;

          return acc;
        }, {});
    },
    chartSeries() {
      if (!this.paymentTypes) return [];
      return Object.values(this.paymentTypes);
    },
    chartOptions() {
      const channelTranslation = this.channelTranslation;
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: Object.keys(this.paymentTypes).map(
          (key) => channelTranslation[key] || key
        ),
        // colors: Object.keys(this.paymentTypes).map(
        //   (key) => channelTranslation[key]?.color || key
        // ),
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value} ingressos${value != 1 ? "s" : ""}`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
