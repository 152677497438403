<template>
  <v-card outlined class="pa-2">
    <div class="d-flex align-center justify-space-between">
      <h6 class="mb-0">Responsaveis</h6>
      <v-btn text @click="copyLinkModal = true" small>
        <v-icon small left>mdi-link-variant</v-icon>
        Copiar link
      </v-btn>
    </div>

    <v-alert v-if="error" type="error" class="mb-0">{{ error }}</v-alert>
    <v-data-table
      :headers="headers"
      :items="Object.values(courtesyEmittersByResponsible)"
      :loading="loading"
      item-key="id"
      sort-by="Payment.createdAt"
      sort-desc
      single-expand
      show-expand
      :expanded.sync="expanded"
    >
      <template v-slot:item.Responsible.name="{ item }">
        <div class="d-flex align-center gap-2">
          <base-avatar
            :src="item.Responsible.photo"
            :seed="item.Responsible.id"
          />
          <div>
            <b class="d-block lh-1">{{ item.Responsible.name }}</b>
            <small class="lh-1">{{ item.Responsible.phone }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.emitted="{ item }">
        {{ item.emitted }}/{{ item.count }}
      </template>
      <template v-slot:item.courtesyEmitters.length="{ item }">
        {{ item.courtesyEmitters.length }} lote{{
          item.courtesyEmitters.length > 1 ? "s" : ""
        }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-3">
          <div class="d-flex flex-column gap-1">
            <template v-for="(courtesyEmitter, i) in item.courtesyEmitters">
              <v-divider v-if="i" :key="`divider-${courtesyEmitter.id}`" />
              <div
                :key="`div-${courtesyEmitter.id}`"
                class="d-flex align-center gap-2"
              >
                <p class="mb-0" style="flex: 2">
                  {{ courtesyEmitter.TicketBlock.TicketGroup.name }} -
                  {{ courtesyEmitter.TicketBlock.name }}
                </p>
                <v-progress-linear
                  height="16"
                  style="flex: 1"
                  :value="
                    (courtesyEmitter.Tickets.length /
                      courtesyEmitter.quantity) *
                    100
                  "
                  class="rounded"
                >
                  {{ courtesyEmitter.Tickets.length }}/{{
                    courtesyEmitter.quantity
                  }}
                </v-progress-linear>
              </div>
            </template>
          </div>

          <v-data-table
            :items="item.Tickets"
            :headers="[
              { text: 'Contemplado', value: 'Owner.name' },
              { text: 'Lote', value: 'TicketBlock.name' },
            ]"
            hide-default-footer
            dense
            class="mt-3"
            :items-per-page="item.emitted"
          >
            <template v-slot:item.Owner.name="{ item }">
              <base-avatar
                :src="item.Owner.photo"
                :seed="item.Owner.id"
                :size="24"
              />
              <span class="ml-2">{{ item.Owner.name }}</span>
            </template>
            <template v-slot:item.TicketBlock.name="{ item }">
              {{ item.TicketBlock.TicketGroup.name }} -
              {{ item.TicketBlock.name }}
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="copyLinkModal" max-width="300">
      <v-card
        class="d-flex flex-column gap-2 pa-6 pb-2 justify-center align-center rounded-lg"
      >
        <!-- <v-icon x-large>mdi-shield-link-variant</v-icon> -->
        <h5 class="text-center">Link para emitir cortesias</h5>

        <qrcode-vue :value="link" :size="200"></qrcode-vue>
        <v-btn @click="copyLink" color="primary" text block>
          <v-icon left>mdi-{{ linkCopied ? "check" : "content-copy" }}</v-icon>
          {{ linkCopied ? "Link copiado" : "Copiar link" }}
        </v-btn>
        <v-alert color="info" class="mb-0" text dense>
          O link só pode ser acessado por responsáveis autorizados.
        </v-alert>
        <v-btn @click="copyLinkModal = false" text block small>
          Fechar
        </v-btn>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import COURTESY_EMITTER from "@/services/admin/courtesyEmitter";
import PAYMENT from "@/utils/payment";

import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PAYMENT,
      courtesyEmitters: [],
      loading: false,
      error: null,
      expanded: [],
      copyLinkModal: false,
      linkCopied: false,
      headers: [
        { text: "Responsavel", value: "Responsible.name" },
        { text: "Emitidas", value: "emitted" },
        { text: "Lotes", value: "courtesyEmitters.length" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    async getCourtesyEmitters() {
      this.loading = true;
      try {
        const { courtesyEmitters } = await COURTESY_EMITTER.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.courtesyEmitters = courtesyEmitters;
      } catch (error) {
        this.error = error.message || "Erro ao buscar cortesias.";
      } finally {
        this.loading = false;
      }
    },
    copyLink() {
      const link = this.link;
      navigator.clipboard.writeText(link).then(() => {
        this.linkCopied = true;
        setTimeout(() => {
          this.linkCopied = false;
        }, 2000);
      });
    },
  },
  computed: {
    courtesyEmittersByResponsible() {
      return this.courtesyEmitters.reduce((acc, item) => {
        const responsibleId = item.Responsible.id;
        if (!acc[responsibleId])
          acc[responsibleId] = {
            Responsible: item.Responsible,
            count: 0,
            emitted: 0,
            courtesyEmitters: [],
            Tickets: [],
          };

        acc[responsibleId].count += item.quantity;
        acc[responsibleId].emitted += item.Tickets.length;
        acc[responsibleId].courtesyEmitters.push(item);
        acc[responsibleId].Tickets.push(
          ...item.Tickets.map((t) => ({ ...t, TicketBlock: item.TicketBlock }))
        );

        return acc;
      }, {});
    },
    link() {
      return `${window.location.origin}/staff/courtesy/${this.party.id}`;
    },
  },
  mounted() {
    this.getCourtesyEmitters();
    this.$root.$on("refresh-courtesy", this.getCourtesyEmitters);
  },
};
</script>

<style>
</style>